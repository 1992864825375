import { useSelector } from 'react-redux';
import ClassNames from '@streamloots/classnames';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'utils/translation';
import trackEventManager from 'utils/event-manager';
import { urls } from 'services/routing';
import { cultureSelectors, produceLegalURLs } from 'services/culture';
import Icon from 'ui-library/icons';
import LanguageSwitcher from 'components/language-switcher';
import theme from './footer.scss';

const classNames = ClassNames(theme);

export type OwnProps = {
  className?: string;
};

type ExtendedProps = OwnProps;

export const Footer = ({ className = '' }: ExtendedProps): JSX.Element => {
  const { t } = useTranslation('common');
  const language = useSelector(cultureSelectors.language);

  // Using language culture when user is logged in and not from the USA according to /heartbeat
  const { termsURL, privacyURL } = produceLegalURLs({
    language,
  });

  return (
    <footer
      className={classNames({
        footer: true,
        [className]: Boolean(className),
      })}
    >
      <div className={classNames('footer__content')}>
        <div className={classNames('footer__links')}>
          <a
            className={classNames('footer__links--blog')}
            href={t('common:footerSubathonLink')}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => trackEventManager.onButtonClick('footerSubathonLink')}
          >
            {t('common:footerSubathon')}
          </a>
          <a
            href={t('common:blogLink')}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => trackEventManager.onButtonClick('footerBlogLink')}
          >
            {t('common:blogSuccess')}
          </a>
          <a
            href={t('common:faqLink')}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => trackEventManager.onButtonClick('footerFaqLink')}
          >
            {t('common:faq')}
          </a>
          <NavLink to={urls().BRAND}>{t('common:pressKit')}</NavLink>
          <NavLink to={termsURL}>{t('common:termsAndConditions')}</NavLink>
          <NavLink to={privacyURL}>{t('common:privacyPolicy')}</NavLink>
          <NavLink to={urls().COOKIES_POLICY}>{t('common:cookiesPolicyLink')}</NavLink>
        </div>
        <div className={classNames('footer__right')}>
          <div className={classNames('footer__languages')}>
            <LanguageSwitcher />
          </div>
          <div className={classNames('footer__copyright')}>
            <span className={classNames('footer__copyright__brand')}>© Streamloots</span>
            <div className={theme.footer__social}>
              <a href={t('common:twitterLink')} rel="noopener noreferrer" target="_blank" title="Twitter">
                <Icon id="twitter" />
              </a>
              <a href="https://discord.com/invite/GRGzCNMFk8" rel="noopener noreferrer" target="_blank" title="Discord">
                <Icon id="discord" />
              </a>
              <a href={t('common:youtubeLink')} rel="noopener noreferrer" target="_blank" title="YouTube">
                <Icon id="youtube" />
              </a>
              <a href={t('common:instagramLink')} rel="noopener noreferrer" target="_blank" title="Instagram">
                <Icon id="instagram" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
