import _clean from 'lodash-clean';
import { achievementsSideEffects } from 'services/sideEffects';
import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { RedemptionRedeemedField } from 'model/redemptionTS';
import type { CardRedeemedAction, CardNotRedeemedAction } from './types';
import { RedemptionActionTypes } from './actionTypes';
import { errorMapping } from './errorMapping';

const origin = 'WWW';

const cardRedeemed = ({ additionalData, data }): CardRedeemedAction =>
  actionCreator.createAction(RedemptionActionTypes.REDEEMED, {
    ...data,
    setId: additionalData.setId,
  });

const cardNotRedeemed = ({ additionalData, errorData }): CardNotRedeemedAction =>
  actionCreator.createErrorAction(RedemptionActionTypes.REDEEMED, errorData, errorMapping, {
    setId: additionalData.setId,
  });

const redeemCard = (
  collectionId: string,
  setCardId: string,
  redeemFields: RedemptionRedeemedField[],
  setId: string,
) => {
  const parameters: Record<string, any> = {
    setCardId,
    redeemFields,
    origin,
  };

  return actionCreator.createAsyncAction({
    additionalData: {
      setId,
    },
    endpoint: endpoints.CARD.REDEEM,
    errorBinding: cardNotRedeemed,
    loadingAction: RedemptionActionTypes.REDEEMING,
    parameters: _clean(parameters),
    method: 'post',
    urlParameters: { collectionId },
    successBinding: cardRedeemed,
    successAsyncSideEffects: achievementsSideEffects,
  });
};

const cardRedemptionTested = () => actionCreator.createAction(RedemptionActionTypes.TEST_SUCCESS);

const cardRedemptionNotTested = ({ errorData }) =>
  actionCreator.createErrorAction(RedemptionActionTypes.TEST_ERROR, errorData);

const testRedemption = (setId: string, cardId: string, redeemFields: RedemptionRedeemedField[]) => {
  const parameters = {
    redeemFields,
  };

  return actionCreator.createAsyncAction({
    endpoint: endpoints.CARD.TEST,
    errorBinding: cardRedemptionNotTested,
    loadingAction: RedemptionActionTypes.TEST_REQUEST,
    parameters: _clean(parameters),
    method: 'post',
    urlParameters: { setId, cardId },
    successBinding: cardRedemptionTested,
  });
};

export const redemptionActions = {
  redeemCard,
  testRedemption,
};
